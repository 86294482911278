<template>
	<v-layout>
		<v-flex class="p-relative">
			<!-- signUpLogo Starts -->
			<div class="signUpLogo thankyou">
				<svg
					data-name="Group 1469"
					xmlns="http://www.w3.org/2000/svg"
					width="466"
					height="276"
					viewBox="0 0 466 276"
				>
					<defs>
						<clipPath id="16bzuuhxda">
							<path
								data-name="Rectangle 1611"
								style="fill: none"
								d="M0 0h466v276H0z"
							/>
						</clipPath>
						<clipPath id="mjm1d153tb">
							<path
								data-name="Rectangle 1600"
								style="fill: none"
								d="M0 0h254.275v170.544H0z"
							/>
						</clipPath>
						<clipPath id="d7bldthjsc">
							<path
								data-name="Rectangle 1601"
								style="fill: none"
								d="M0 0h2.032v86.526H0z"
							/>
						</clipPath>
						<clipPath id="3ozmkqf1vd">
							<path
								data-name="Rectangle 1602"
								style="fill: none"
								d="M0 0h22.243v26.041H0z"
							/>
						</clipPath>
						<clipPath id="k9iab737le">
							<path
								data-name="Rectangle 1603"
								style="fill: none"
								d="M0 0h8.549v18.217H0z"
							/>
						</clipPath>
						<clipPath id="rqk8hl8ghf">
							<path
								data-name="Rectangle 1604"
								style="fill: none"
								d="M0 0h17.541v100.675H0z"
							/>
						</clipPath>
						<clipPath id="7kovh4qlbg">
							<path
								data-name="Rectangle 1605"
								style="fill: none"
								d="M0 0h26.03v104.085H0z"
							/>
						</clipPath>
						<clipPath id="bvwl0c9nph">
							<path
								data-name="Rectangle 1606"
								style="fill: none"
								d="M0 0h7.365v59.317H0z"
							/>
						</clipPath>
						<clipPath id="w053f4jvji">
							<path
								data-name="Rectangle 1607"
								style="fill: none"
								d="M0 0h52.657v19.179H0z"
							/>
						</clipPath>
						<clipPath id="grivc6r8yj">
							<path
								data-name="Rectangle 1608"
								style="fill: none"
								d="M0 0h78.343v134.453H0z"
							/>
						</clipPath>
					</defs>
					<g data-name="Group 1465">
						<g data-name="Group 1464" style="clip-path: url(#16bzuuhxda)">
							<g data-name="Group 1439" style="opacity: 0.1">
								<g data-name="Group 1438">
									<g
										data-name="Group 1437"
										style="clip-path: url(#mjm1d153tb)"
										transform="translate(111.986 101.737)"
									>
										<path
											data-name="Path 2074"
											d="M366 210.872c-3.942-33.88-22.863-66.108-61.492-90.9s-135.6-32.228-174.228 31.4c-28.5 46.938-17.1 97.017-8.2 121.059h232.409c6.686-18.211 13.691-42.8 11.508-61.563"
											transform="translate(-112.129 -101.89)"
											style="fill: #adefdd"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2075"
								d="M115.819 275.844v-74.371s-.827-20.039 9.712-25.823 54.965-2.686 54.965-2.686v102.88z"
								transform="translate(-.148 -.258)"
								style="fill: #c62772"
							/>
							<path
								data-name="Path 2076"
								d="M194.461 103.242s-2.687 22.518-3.719 24.377l-1.034 1.859-5.165 3.512s9.918 14.048 22.11 16.321 11.158-4.959 11.158-4.959l-1.86-3.925 2.48-21.485s-22.11-10.123-23.969-15.7"
								transform="translate(-.236 -.155)"
								style="fill: #ffa69f"
							/>
							<path
								data-name="Path 2077"
								d="m178.129 134.892 8.059-3.512s5.165 8.47 17.15 13.428 12.191 2.066 12.812 0a7.851 7.851 0 0 0-.207-4.338l.887-7.233 4.793 1.6 16.636 134.543-42.773 2.066z"
								transform="translate(-.228 -.197)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2078"
								d="M217.646 133.38s2.892 6.68 5.579 22.38 5.166 42.144 5.786 60.324 1.034 59.7 1.034 59.7h53.931c.62 0 4.339-20.452 4.339-20.452s-23.557-20.177-24.8-23.482c-.827-2.2-.413-43.04-3.926-61.838s-8.059-22.587-17.564-27.27c-9.823-4.839-24.383-9.365-24.383-9.365"
								transform="translate(-.278 -.2)"
								style="fill: #83b23c"
							/>
							<g data-name="Group 1442" style="opacity: 0.2">
								<g data-name="Group 1441">
									<g
										data-name="Group 1440"
										style="clip-path: url(#d7bldthjsc)"
										transform="translate(243.937 165.39)"
									>
										<path
											data-name="Path 2079"
											d="M245.782 252.163a.5.5 0 0 1-.5-.5c0-1.478-.263-22.462-.516-42.757s-.516-41.286-.516-42.77a.5.5 0 0 1 1 0c0 1.477.263 22.462.515 42.757s.517 41.286.517 42.77a.5.5 0 0 1-.5.5"
											transform="translate(-244.249 -165.638)"
											style="fill: #36393f"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2080"
								d="m138.2 247.242-.413 28.716H200.6s2.686-24.377 1.653-26.65-64.056-2.066-64.056-2.066"
								transform="translate(-.176 -.37)"
								style="fill: #82295b"
							/>
							<g
								data-name="Group 1445"
								style="mix-blend-mode: multiply; isolation: isolate"
							>
								<g data-name="Group 1444">
									<g
										data-name="Group 1443"
										style="clip-path: url(#3ozmkqf1vd)"
										transform="translate(195.952 105.523)"
									>
										<path
											data-name="Path 2081"
											d="M208.183 127a32.877 32.877 0 0 0 8.788 4.72l1.475-12.777s-16.608-7.606-22.243-13.265c1.177 6.43 4.074 14.95 11.98 21.322"
											transform="translate(-196.203 -105.681)"
											style="fill: #8e9bbb"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2082"
								d="M237.862 61.235a44.686 44.686 0 0 1 5.165 16.527c.827 9.089 0 32.433-7.438 42.349s-22.936 7.024-29.962 1.033c-6.448-5.5-12.811-11.569-14.464-23.551s3.514-34.707 18.184-39.459 28.516 3.1 28.516 3.1"
								transform="translate(-.244 -.085)"
								style="fill: #ffa69f"
							/>
							<path
								data-name="Path 2083"
								d="M256.516 262.967s-.729 0-1.838-.03c-.26-.01-.549 0-.849-.01-1.069-.03-2.367-.06-3.675-.1l-.879-.03c-1.3-.03-2.407.13-3.446.07h-.03c-.22-.01-.539.02-.8.03a1.944 1.944 0 0 1-.33.01 4.854 4.854 0 0 1-1.368-.539 92.923 92.923 0 0 0-21.433-2.856c-9.228.04-17.508.669-18.856-.459-1.358-1.148-2.177-12.741 5.7-13.5l9.688 3.674s22.122-2.626 30.231 3.355c8.13 5.981 7.88 10.384 7.88 10.384"
								transform="translate(-.258 -.368)"
								style="fill: #ff9d8c"
							/>
							<path
								data-name="Path 2084"
								d="M245.841 262.883c-.22-.01-.54.02-.8.03a9.378 9.378 0 0 0-.85-1.138c-2.5-2.546-8.528-4.194-10.715-4.723a.374.374 0 0 1-.28-.449.383.383 0 0 1 .458-.28c3.186.769 9.948 2.736 11.865 5.961.06.09.221.4.321.6"
								transform="translate(-.298 -.384)"
								style="fill: #223a7a"
							/>
							<path
								data-name="Path 2085"
								d="M250.2 262.839c-.29-.007-.589-.014-.879-.031-2.726-4.971-7.186-6.4-12.7-8.171l-.327-.108a.376.376 0 1 1 .237-.713l.318.108c5.829 1.869 10.517 3.372 13.349 8.915"
								transform="translate(-.302 -.38)"
								style="fill: #223a7a"
							/>
							<path
								data-name="Path 2086"
								d="M254.727 262.946c-.26-.006-.549 0-.849-.011a21.778 21.778 0 0 0-14.366-10.647.376.376 0 0 1-.31-.437.386.386 0 0 1 .437-.3c7.458 1.4 13.474 7.489 15.087 11.394"
								transform="translate(-.306 -.377)"
								style="fill: #223a7a"
							/>
							<path
								data-name="Path 2087"
								d="M196.11 87.345a19.34 19.34 0 0 0 7.749-8.264 23.517 23.517 0 0 0 2.789-11.569s5.786-.93 14.463 1.653 19.011 6.094 27.69 1.136 8.678-12.912 4.442-16.217c-4.744-3.7-14.155-1.447-14.155-1.447s3.616-3.512 1.654-5.578-9.092.62-9.092.62-8.679-6.921-25.106-6.095-24.279 12.293-25.209 25.1 3.72 25.616 3.72 25.616z"
								transform="translate(-.231 -.062)"
								style="fill: #5e5e5e"
							/>
							<g
								data-name="Group 1448"
								style="mix-blend-mode: multiply; isolation: isolate"
							>
								<g data-name="Group 1447">
									<g
										data-name="Group 1446"
										style="clip-path: url(#k9iab737le)"
										transform="translate(224.958 90.137)"
									>
										<path
											data-name="Path 2088"
											d="M229.494 90.523c-.931 2.295-.908 4.958-1.143 7.393-.183 1.9-.53 4.1 1.205 5.382.829.611 4.751 2.26 2.735 3.595a5.315 5.315 0 0 1-2.934.33c-.6-.029-1.211-.054-1.814-.1-.648-.048-1.283-.244-1.932-.247a.378.378 0 0 0-.19.7 8.161 8.161 0 0 0 3.789.9 5.72 5.72 0 0 0 3.137-.552 2.243 2.243 0 0 0 1.433-1.607 2.566 2.566 0 0 0-1.234-2.212c-.781-.579-1.74-.849-2.529-1.414-1.094-.783-1-2.1-.927-3.323.185-2.937.86-5.823 1.123-8.747.041-.447-.578-.447-.72-.1"
											transform="translate(-225.246 -90.272)"
											style="fill: #8e9bbb"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2089"
								d="M237.95 97.4c-.309 1.741-1.575 2.974-2.83 2.751s-2.022-1.813-1.714-3.555 1.575-2.974 2.83-2.751 2.022 1.813 1.714 3.555"
								transform="translate(-.298 -.141)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2090"
								d="M222.118 96.015c-.28 1.879-1.6 3.238-2.957 3.037s-2.225-1.888-1.946-3.766 1.6-3.239 2.958-3.037 2.224 1.887 1.945 3.766"
								transform="translate(-.277 -.138)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2091"
								d="M234.269 90.21c.966.163 1.941 0 2.914.065.234.017.465.051.7.074.165.016-.31-.049-.079-.011l.149.024c.122.022.244.047.365.077.207.05.407.128.615.174a2.371 2.371 0 0 0 .588.076l.319-.042-.2.036a1.95 1.95 0 0 0 .4-.043 1.075 1.075 0 0 0 .741-1.305 1.232 1.232 0 0 0-.212-.421 6.358 6.358 0 0 0-.126-.161l.117.134a3.454 3.454 0 0 0-.129-.17.623.623 0 0 0-.221-.221c-.077-.059-.153-.122-.234-.177a2.81 2.81 0 0 0-.9-.36 7.394 7.394 0 0 0-1.776-.228 12.341 12.341 0 0 0-1.758.14 3.387 3.387 0 0 0-1.506.557 1 1 0 0 0-.465.972.957.957 0 0 0 .7.811"
								transform="translate(-.298 -.131)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2092"
								d="M223.223 87.444a4.058 4.058 0 0 0-.4-.209c-.118-.054-.437-.222-.2-.089-.088-.048-.176-.1-.265-.142a6.1 6.1 0 0 0-1.248-.447 8.306 8.306 0 0 0-2.9-.2 6.743 6.743 0 0 0-2.867.9 1.827 1.827 0 0 0-.361.256c-.071.09-.141.181-.209.272l.059-.09a1.017 1.017 0 0 0-.433.842.839.839 0 0 0 .446.744 1.262 1.262 0 0 0 1 .008h-.522a1.875 1.875 0 0 0 .778-.007c.011 0 .279-.069.1-.032s.282-.034.331-.041.368-.039.039-.007c.061-.006.121-.014.181-.022.157-.019.312-.038.468-.051a43.676 43.676 0 0 1 2.045-.107 25.99 25.99 0 0 1 1.914 0c.272.012.542.021.814.029.154 0 .088.028-.016 0 .055.017.114.028.17.043.113.032.224.068.334.11a.95.95 0 0 0 1.132-.555.962.962 0 0 0-.393-1.2"
								transform="translate(-.274 -.129)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2093"
								d="M228.137 116.2a9.344 9.344 0 0 1 4.663-4.2c-1.732-.176-9.905-.933-11.968.239-2.311 1.312.426 6.578 5.205 6.843a6.532 6.532 0 0 0 1.393-.081 6.9 6.9 0 0 1 .7-2.8"
								transform="translate(-.281 -.167)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2094"
								d="M190.336 105.1s.827 3.2 5.579 6.921a55.106 55.106 0 0 0 11.64 6.438l.551-2.409s-7.129-3.1-10.435-6.508-3.719-5.268-3.719-5.268z"
								transform="translate(-.243 -.156)"
								style="fill: #dee1eb"
							/>
							<path
								data-name="Path 2095"
								d="M208.953 114.284a5.975 5.975 0 0 1 6.226 4.8c.267 1.956-1.571 4.15-5.87 2.223-5.34-2.393-4.18-6.847-.356-7.024"
								transform="translate(-.263 -.171)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2096"
								d="M193.325 81.355s1.652-11.872 9.609-19.935c15.188-15.391 28.1-14.461 28.1-14.461s-.1-2.17-4.96-3.822c-4.559-1.552-6.613-.723-6.613-.723s-12.5 1.033-23.039 12.7-11.672 26.551-11.672 26.551z"
								transform="translate(-.236 -.063)"
								style="fill: #dee1eb"
							/>
							<path
								data-name="Path 2097"
								d="M192.178 79.966c-9.949-1.685-15.5 6.095-15.911 13.944s5.292 13.819 12.914 13.222c17.15-1.343 15.188-25.1 3-27.166"
								transform="translate(-.225 -.119)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2098"
								d="M187.228 99.509a6.781 6.781 0 0 1-6.115-3.644 7.552 7.552 0 0 1 .291-7.806.374.374 0 0 1 .619.421 6.8 6.8 0 0 0-.252 7.026 6.02 6.02 0 0 0 5.648 3.25 6.557 6.557 0 0 0 5.425-2.928 8.275 8.275 0 0 0 .819-7.336 5.585 5.585 0 0 0-5.573-3.664.374.374 0 0 1-.1-.742 6.262 6.262 0 0 1 6.373 4.14 9.047 9.047 0 0 1-.9 8.019 7.288 7.288 0 0 1-6.027 3.26h-.211"
								transform="translate(-.23 -.126)"
								style="fill: #818fb3"
							/>
							<path
								data-name="Path 2099"
								d="M179.919 132s-30.168 7.437-40.707 19.419-15.5 71.066-15.7 82.015 7.438 15.494 11.985 18.593 24.59 8.676 40.707 9.5 31.408 0 31.408 0a21.456 21.456 0 0 1 2.428-8.676c2.3-4.791 5.217-5.785 5.217-5.785l-13.844-4.958s-2.424-43.429-6.2-64.455C190.87 153.485 179.919 132 179.919 132"
								transform="translate(-.158 -.198)"
								style="fill: #83b23c"
							/>
							<g data-name="Group 1451" style="opacity: 0.2">
								<g data-name="Group 1450">
									<g
										data-name="Group 1449"
										style="clip-path: url(#rqk8hl8ghf)"
										transform="translate(221.207 134.3)"
									>
										<path
											data-name="Path 2100"
											d="M230.049 235.177a.5.5 0 0 1-.456-.7c2.826-6.376 5.756-18.894 7.4-27.675l.136-.71c.5-2.583 1.121-5.8-.119-8.013-.632-1.131-2.06-3.586-3.434-5.945-1.425-2.45-2.77-4.764-3.144-5.454-.728-1.349 1.563-4.952 4.88-9.894.9-1.335 1.67-2.488 2.121-3.269 1.182-2.045.4-3.918-.515-6.088l-.311-.747c-.826-2.027-1.765-4.058-2.672-6.022-.372-.8-.743-1.606-1.109-2.413q-.727-1.6-1.465-3.253a190.366 190.366 0 0 0-9.805-19.737.5.5 0 0 1 .858-.51 191.14 191.14 0 0 1 9.858 19.839q.74 1.648 1.463 3.25.55 1.207 1.106 2.406c.913 1.974 1.856 4.016 2.691 6.064l.307.738c.929 2.211 1.89 4.5.459 6.973-.468.811-1.25 1.977-2.157 3.326-1.729 2.577-5.327 7.941-4.83 8.863.366.677 1.708 2.984 3.128 5.427 1.376 2.365 2.808 4.826 3.443 5.959 1.418 2.536.757 5.948.228 8.69l-.136.7c-.6 3.2-3.783 19.573-7.472 27.9a.5.5 0 0 1-.456.3"
											transform="translate(-221.49 -134.501)"
											style="fill: #36393f"
										/>
									</g>
								</g>
							</g>
							<g data-name="Group 1454" style="opacity: 0.2">
								<g data-name="Group 1453">
									<g
										data-name="Group 1452"
										style="clip-path: url(#7kovh4qlbg)"
										transform="translate(174.79 132.646)"
									>
										<path
											data-name="Path 2101"
											d="M200.544 236.93a.5.5 0 0 1-.355-.148c-2.348-2.365-13.671-21.967-14.513-25.654-.462-2.018.7-7.136 1.817-12.087.868-3.835 1.766-7.8 1.511-8.875-.382-1.606-3.277-3.97-6.077-6.257-3.383-2.762-6.88-5.62-7.557-8.193-.77-2.925-.112-21.234.324-33.349.157-4.356.28-7.8.28-9.024a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5c0 1.246-.124 4.695-.281 9.061-.406 11.324-1.089 30.276-.357 33.059.6 2.259 4.117 5.135 7.225 7.673 3.063 2.5 5.956 4.866 6.417 6.8.309 1.3-.533 5.018-1.508 9.325-1.045 4.616-2.229 9.847-1.819 11.646.827 3.614 11.944 22.849 14.25 25.171a.5.5 0 0 1-.355.852"
											transform="translate(-175.014 -132.845)"
											style="fill: #36393f"
										/>
									</g>
								</g>
							</g>
							<g data-name="Group 1457" style="opacity: 0.2">
								<g data-name="Group 1456">
									<g
										data-name="Group 1455"
										style="clip-path: url(#bvwl0c9nph)"
										transform="translate(159.218 168.841)"
									>
										<path
											data-name="Path 2102"
											d="M159.921 228.411a.549.549 0 0 1-.07 0 .5.5 0 0 1-.425-.564c.368-2.6 6.1-54.32 6.362-58.282a.494.494 0 0 1 .532-.464.5.5 0 0 1 .464.531c-.264 3.968-6 55.754-6.369 58.354a.5.5 0 0 1-.494.429"
											transform="translate(-159.422 -169.094)"
											style="fill: #36393f"
										/>
									</g>
								</g>
							</g>
							<g data-name="Group 1460" style="opacity: 0.2">
								<g data-name="Group 1459">
									<g
										data-name="Group 1458"
										style="clip-path: url(#w053f4jvji)"
										transform="translate(148.68 223.026)"
									>
										<path
											data-name="Path 2103"
											d="M201.027 242.54a.522.522 0 0 1-.188-.036c-1.269-.515-6.882-2.407-13.986-4.8-14.341-4.835-33.98-11.456-37.715-13.4a.5.5 0 1 1 .46-.885c3.666 1.9 24.071 8.783 37.573 13.335 7.121 2.4 12.746 4.3 14.043 4.823a.5.5 0 0 1-.188.963"
											transform="translate(-148.87 -223.361)"
											style="fill: #36393f"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2104"
								d="M194.118 263.18c-12.621 0-37.088-2.275-55.674-8.847a.5.5 0 0 1 .333-.943c23.48 8.3 56.326 9.722 62.437 8.35a.5.5 0 1 1 .219.975 39.851 39.851 0 0 1-7.315.464"
								transform="translate(-.176 -.379)"
								style="fill: #8593b6"
							/>
							<g data-name="Group 1463" style="opacity: 0.8">
								<g data-name="Group 1462">
									<g
										data-name="Group 1461"
										style="clip-path: url(#grivc6r8yj)"
										transform="translate(32.112 102.585)"
									>
										<path
											data-name="Path 2105"
											d="M49.549 138.923c3.918 3.446 8.881 5.655 12.318 9.638 1.353 1.568 2.568 3.236 3.824 4.88.914 1.2 3.354 3.18 3.75 4.684.174.662.137-.235-.038-.9-.007-.028-.022-.055-.03-.083-.343-3.044-.589-6.1-.7-9.163s.083-6.121-.108-9.17c-.271-4.3-1.614-8.468-5.13-11.163-1.72-1.318-3.929-2.12-5.51-3.582-1.5-1.388-.9-2.978-.016-4.525A15.414 15.414 0 0 0 60.061 115a8.013 8.013 0 0 0-1.308-5.464c-2.046-3.434-6.585-7.264-10.882-6.75-3.583.428-3.99 4.561-3.939 7.442.05 2.8.327 5.59.354 8.391.028 2.879-.461 5.7-.5 8.567-.07 4.873 2.182 8.586 5.766 11.736"
											transform="translate(-32.167 -102.739)"
											style="fill: #b2c337"
										/>
										<path
											data-name="Path 2106"
											d="M85.936 183.669a45.978 45.978 0 0 1 3.881-7.4c2.111-3.246 4.452-6.408 5.87-10.042 1.448-3.71 1.235-7.679 1.378-11.587.1-2.872.958-5.734.728-8.613-.111-1.381-.907-3.615-2.694-3.386-1.39.178-2.216 1.9-2.75 3a52.829 52.829 0 0 0-2.271 5.979c-.271.787-.528 1.59-.87 2.349-.2.442-.832 1.521-1.428 1.373-1.015-.251-.094-4.394.057-5.128.759-3.686 1.9-7.112 2.058-10.906.23-5.5-.2-12.047-5.578-15.054-1.407-.786-3.189-1.254-4.525-.079-1.318 1.16-1.691 3.17-1.887 4.826-.6 5.079.6 9.889 2.223 14.668 1.344 3.946 2.946 8.018 3.063 12.238.113 4.062-2 7.662-2.993 11.5a36.35 36.35 0 0 0-1.261 11.338c-.01 2.48.08 4.935.262 7.409.167 2.274.364 4.548.642 6.811.056.458.108.937.169 1.415-.874-2.1-1.179-4.514-1.9-6.628-1.286-3.757-2.779-7.438-4.014-11.214a93.145 93.145 0 0 0-3.33-9.262c-1.263-2.813-2.788-5.788-5.144-7.853a7.507 7.507 0 0 0-5.055-1.964 13.97 13.97 0 0 0-3.476.739 3.916 3.916 0 0 1-3.736-.657c-3.856-2.964-5.029-8.278-7.884-12.029-2.129-2.8-7.181-7.57-10.9-4.518-1.97 1.62-2.514 4.748-2.411 7.146a17.311 17.311 0 0 0 3.011 8.276c1.666 2.676 3.817 5.071 5.348 7.821a8.306 8.306 0 0 1 .9 5.015 11.943 11.943 0 0 0 .3 5.063c1.767 5 7.287 4.3 11.58 4.435a32.133 32.133 0 0 1 9.078 1.5 10.091 10.091 0 0 1 6.619 6.513c.853 2.359 1.308 5.037 2.914 7.041a7.5 7.5 0 0 0 2.854 2.125c1.675.74 6.778 1.061 6.816.6a22.886 22.886 0 0 1 1.329-4.688c.869-2.777 1.891-5.485 3.016-8.168"
											transform="translate(-32.153 -102.77)"
											style="fill: #b2c337"
										/>
										<path
											data-name="Path 2107"
											d="M109.7 182.8c-.443-2.86-.359-6.011-1.713-8.637a.391.391 0 0 0-.527-.138c-.971.537-1.012 2.106-1.091 3.066-.132 1.594-.034 3.191-.23 4.779a35.848 35.848 0 0 1-2 8.3 8.927 8.927 0 0 1-6.214 5.594 15.755 15.755 0 0 0-5.439 2.4 11.472 11.472 0 0 0-3.19 4.4c-2.007 4.5-2.963 9.531-3.893 14.337-.627 3.237-1.138 6.489-1.925 9.693a95.378 95.378 0 0 0-2.08 9.662c-.076.165-.156.327-.227.494-.206.489.559.754.824.347 3.1-4.776 6.883-9.062 12.041-11.692 2.329-1.188 5.423-1.434 7.366-3.3 1.478-1.42 1.682-3.586 1.461-5.516-.291-2.538-1.507-4.626-2.773-6.789-.7-1.186-1.518-2.459-1.237-3.9a5.031 5.031 0 0 1 2.522-3.06c2.143-1.331 4.65-1.93 6.406-3.848a10.517 10.517 0 0 0 2.716-6.4 44.13 44.13 0 0 0-.8-9.788"
											transform="translate(-32.215 -102.846)"
											style="fill: #b2c337"
										/>
										<path
											data-name="Path 2108"
											d="M67.349 199.311a41.176 41.176 0 0 0-9.763-9.037c-1.983-1.317-6.665-3.917-7.609-.03-.554 2.278 1.676 4.518 3.143 5.946 2.205 2.145 4.756 4 6.617 6.469-2.3.735-4.711.878-6.712 2.47-2.37 1.885-3.434 5.527-1.084 7.836a14.892 14.892 0 0 0 4.09 2.506c1.854.9 3.741 1.71 5.641 2.5 4.1 1.713 8.257 3.452 11.756 6.258 3.565 2.861 6.917 7.7 7.2 12.362.027.445-.142.039.124-.164.146.13.634-.1.632-.363a49.215 49.215 0 0 0-4.148-19.86 82.936 82.936 0 0 0-9.887-16.9"
											transform="translate(-32.175 -102.867)"
											style="fill: #b2c337"
										/>
									</g>
								</g>
							</g>
							<path
								data-name="Path 2109"
								d="M57.9 134.774c1.511 3.134 3.091 6.2 4.768 9.253a74.493 74.493 0 0 1 4.433 9c1.487 3.866 3.046 7.673 4.618 11.5a113.526 113.526 0 0 1 4.267 12.182 144.575 144.575 0 0 1 4.826 24.774 100.6 100.6 0 0 1 .5 12.8c-.091 3.339-.467 6.643-.782 9.965a59.013 59.013 0 0 0-.273 10.423c.242 2.9.7 6.346 2.954 8.415a.518.518 0 0 0 .809-.624c-2.675-5.485-2.525-11.462-2.013-17.409.282-3.282.674-6.548.784-9.843a98.531 98.531 0 0 0-.418-12.569 148.7 148.7 0 0 0-4.536-24.279 109.547 109.547 0 0 0-3.989-12.013c-1.556-3.937-3.353-7.767-4.861-11.724-2.728-7.156-6.884-13.5-10.564-20.171-.191-.346-.693-.042-.525.308"
								transform="translate(-.074 -.201)"
								style="fill: #779b37"
							/>
							<path
								data-name="Path 2110"
								d="M80.963 193.337c.432-5.212.895-10.23 2.652-15.2.819-2.319 1.813-4.573 2.6-6.9.653-1.924 1.631-4.563 1.128-6.607a.236.236 0 0 0-.451 0c-.337 1.168-.3 2.448-.541 3.647a32.3 32.3 0 0 1-.914 3.443c-.771 2.408-1.779 4.717-2.652 7.086a35.064 35.064 0 0 0-2.185 14.531.179.179 0 0 0 .359 0"
								transform="translate(-.103 -.246)"
								style="fill: #779b37"
							/>
							<path
								data-name="Path 2111"
								d="M50.72 163.384c2.069 5 8.288 5.285 12.819 6.4a12.018 12.018 0 0 1 7.339 5.758 44.048 44.048 0 0 1 4.191 10.082.031.031 0 0 0 .06-.016c-1.266-5.99-3.082-13.2-9.258-15.989-4.949-2.234-11.47-1.112-14.636-6.456-.156-.263-.639-.086-.513.217"
								transform="translate(-.065 -.244)"
								style="fill: #779b37"
							/>
							<path
								data-name="Path 2112"
								d="M77.689 192.587c.246.333.471.679.726 1.005a2.705 2.705 0 0 0 .411.443.963.963 0 0 0 .576.294.126.126 0 0 0 .108-.188 1.98 1.98 0 0 0-.431-.394c-.148-.131-.3-.26-.443-.4-.289-.274-.553-.57-.837-.85-.054-.054-.159.019-.11.085"
								transform="translate(-.099 -.288)"
								style="fill: #43578e"
							/>
							<path
								data-name="Path 2113"
								d="M64.734 208.463a50.342 50.342 0 0 1 9.477 10.192 61.629 61.629 0 0 1 3.453 5.676 63.341 63.341 0 0 1 2.789 6.579c.068.169.376.172.353-.048-.5-4.7-3.474-8.728-5.858-12.638a30.133 30.133 0 0 0-4.435-5.363 39.367 39.367 0 0 0-5.457-4.815c-.244-.172-.555.225-.322.416"
								transform="translate(-.083 -.312)"
								style="fill: #779b37"
							/>
							<path
								data-name="Path 2114"
								d="M93.176 202.923c-1.719.974-1.775 3.688-1.809 5.422-.065 3.33.76 6.457-.7 9.632-1.32 2.866-3.43 5.259-5.088 7.921-1.552 2.491-3.084 5.676-3.049 8.676 0 .174.268.271.349.094a36.155 36.155 0 0 0 1.3-4.335 18.193 18.193 0 0 1 2.117-4.472c1.6-2.472 3.555-4.723 4.85-7.382 1.364-2.8 1.165-5.579 1.075-8.615-.058-1.936-.252-5.047 1.31-6.487.245-.226-.068-.613-.35-.453"
								transform="translate(-.105 -.304)"
								style="fill: #779b37"
							/>
							<path
								data-name="Path 2115"
								d="M81.655 234.931c-6.924-.1-20.113-.172-20.113-.172l3.264 24.225a10.986 10.986 0 0 0 10.888 9.516h11.923a10.986 10.986 0 0 0 10.883-9.516l3.263-24.225s-13.188.076-20.112.172"
								transform="translate(-.079 -.352)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2116"
								d="m416.586 190.343-39.5 78.228-115.163.826 35.541-80.155z"
								transform="translate(-.335 -.283)"
								style="fill: #f6f7fa"
							/>
							<path
								data-name="Path 2117"
								d="m185.392 268.908.022-6.262h74.551l34.3-75.2h119.227l3 2.892H300.016l-35.644 78.5s-78.98-.143-78.98.064"
								transform="translate(-.237 -.281)"
								style="fill: #dadee8"
							/>
							<path
								data-name="Path 2118"
								d="m326.067 235.858 5.086-4.186 6.745 8.588-5.085 4.188z"
								transform="translate(-.417 -.347)"
								style="fill: #da8f22"
							/>
							<path
								data-name="Path 2119"
								d="m332.822 244.407-6.734-8.574 5.085-4.185z"
								transform="translate(-.417 -.347)"
								style="fill: #c73634"
							/>
							<path
								data-name="Path 2120"
								d="M335.613 231.437c-1.4 1.155-3.276 1.157-4.184 0l13.47 17.149 5.083-4.185-13.47-17.149c.908 1.153.507 3.026-.895 4.182z"
								transform="translate(-.423 -.34)"
								style="fill: #82295b"
							/>
							<path
								data-name="Path 2121"
								d="m336.117 237.373 8.819 11.228 5.086-4.185c-.034 0-13-5.886-13.9-7.043"
								transform="translate(-.429 -.356)"
								style="fill: #c62772"
							/>
							<path
								data-name="Path 2122"
								d="M351.177 239.971c1.4-1.155 3.276-1.157 4.184 0l-13.47-17.149-5.078 4.151 13.463 17.182a3.179 3.179 0 0 1 .9-4.184"
								transform="translate(-.43 -.334)"
								style="fill: #b2c337"
							/>
							<path
								data-name="Path 2123"
								d="m341.893 222.822-5.078 4.151 8.552 10.889c-.916-1.123-3.475-15.039-3.475-15.039"
								transform="translate(-.43 -.334)"
								style="fill: #83b23c"
							/>
							<path
								data-name="Path 2124"
								d="M348.3 230.227a1.6 1.6 0 0 1 .37.34 1.16 1.16 0 0 1 .691.1 7.188 7.188 0 0 0 2.706.239c3-.34 4.258-2.076 3.3-4.594a7.294 7.294 0 0 0-.4-.988c-.49-.885-2.269-.681-2.924.307a.725.725 0 0 1-.8.408.554.554 0 0 1-.417-.749 2.126 2.126 0 0 1 1.739-1.6 5.806 5.806 0 0 1 2.972.1c.67.2.963-.2 1.15-.749a1.41 1.41 0 0 0-.207-1.259 19.648 19.648 0 0 0-2.15-2.824c-1.009-1.326-2.175-2.551-2.25-4.422-3.293 2.654-6.55 5.308-9.809 7.962 1.873 2.689 3.911 5.241 6.032 7.723"
								transform="translate(-.437 -.321)"
								style="fill: #adefdd"
							/>
							<path
								data-name="Path 2125"
								d="M352.081 214.542a2412.004 2412.004 0 0 0-9.809 7.962c1.326 1.905 2.776 3.708 4.228 5.511a18.2 18.2 0 0 1 .409-3.333 22.92 22.92 0 0 1 5.172-10.14"
								transform="translate(-.437 -.321)"
								style="fill: #86c9b3"
							/>
							<path
								data-name="Path 2126"
								d="M264.473 269.347a.508.508 0 0 1-.207-.045.5.5 0 0 1-.248-.661l35.776-78.8H416.59a.5.5 0 1 1 0 1H300.439l-35.512 78.211a.5.5 0 0 1-.454.293"
								transform="translate(-.337 -.284)"
								style="fill: #fff"
							/>
							<path
								data-name="Rectangle 1609"
								transform="translate(0 268.563)"
								style="fill: #fff"
								d="M0 0h466v7.437H0z"
							/>
							<path
								data-name="Path 2127"
								d="M145.5 109.343H97.134a5.419 5.419 0 0 1-5.419-5.418V79.787a5.42 5.42 0 0 1 5.419-5.419H145.5a5.419 5.419 0 0 1 5.419 5.419v24.138a5.418 5.418 0 0 1-5.419 5.418"
								transform="translate(-.117 -.111)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2128"
								d="M164.768 91.87 146.6 84.208v15.325z"
								transform="translate(-.187 -.126)"
								style="fill: #fff"
							/>
							<path
								data-name="Rectangle 1610"
								transform="translate(109.51 81.815)"
								style="fill: #da8f22"
								d="M0 0h23.377v15.582H0z"
							/>
							<path
								data-name="Path 2129"
								d="M139.836 99.407h-37.014a.98.98 0 0 0-.974.974v.974a3.9 3.9 0 0 0 3.9 3.9h31.17a3.9 3.9 0 0 0 3.9-3.9v-.974a1.014 1.014 0 0 0-.974-.974m-30.2-17.475h23.377v15.582h3.9V80.959a2.932 2.932 0 0 0-2.922-2.922h-25.333a2.932 2.932 0 0 0-2.922 2.922v16.555h3.9z"
								transform="translate(-.13 -.117)"
								style="fill: #36393f"
							/>
							<path
								data-name="Path 2130"
								d="M391.935 0H280.284A12.982 12.982 0 0 0 267.3 12.981v52.954a12.982 12.982 0 0 0 12.983 12.981h10.007c.282 4.8-.406 11.942-4.415 21.072a56.1 56.1 0 0 0 24.207-21.072h81.851a12.982 12.982 0 0 0 12.983-12.981V12.981A12.982 12.982 0 0 0 391.935 0"
								transform="translate(-.341)"
								style="fill: #878787"
							/>
							<path
								data-name="Path 2131"
								d="M344.331 25.268h-55.378a2.754 2.754 0 1 1 0-5.509h55.377a2.754 2.754 0 1 1 0 5.509"
								transform="translate(-.366 -.03)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2132"
								d="M384.038 41.341h-96.153a1.687 1.687 0 1 1 0-3.374h96.153a1.687 1.687 0 1 1 0 3.374"
								transform="translate(-.366 -.057)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2133"
								d="M384.038 50.72h-96.153a1.687 1.687 0 1 1 0-3.374h96.153a1.687 1.687 0 1 1 0 3.374"
								transform="translate(-.366 -.071)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2134"
								d="M363.064 60.72h-75.178a1.687 1.687 0 1 1 0-3.374h75.178a1.687 1.687 0 1 1 0 3.374"
								transform="translate(-.366 -.086)"
								style="fill: #3f3f3f"
							/>
							<path
								data-name="Path 2135"
								d="M383.37 25.268h-26.418a2.754 2.754 0 1 1 0-5.509h26.418a2.754 2.754 0 1 1 0 5.509"
								transform="translate(-.452 -.03)"
								style="fill: #fff"
							/>
							<path
								data-name="Path 2136"
								d="M290.887 144.365a2.161 2.161 0 0 1-2.163-2.163v-12.98a2.161 2.161 0 0 1 2.163-2.164h21.639a2.162 2.162 0 0 1 2.164 2.164V142.2a2.162 2.162 0 0 1-2.164 2.163zm0-2.163h21.639v-12.98h-21.639z"
								transform="translate(-.369 -.19)"
								style="fill: #83b23c"
							/>
							<path
								data-name="Path 2137"
								d="M312.518 142.2h-21.639v-12.985h21.639zm-25.967 6.49a4.329 4.329 0 0 1-4.327-4.327v-5.409a3.245 3.245 0 1 0 0-6.49v-5.409a4.331 4.331 0 0 1 4.327-4.327h30.3a4.329 4.329 0 0 1 4.327 4.327v5.409a3.245 3.245 0 1 0 0 6.49v5.409a4.327 4.327 0 0 1-4.327 4.327zm28.131-19.471a2.162 2.162 0 0 0-2.164-2.164H290.88a2.162 2.162 0 0 0-2.164 2.164V142.2a2.162 2.162 0 0 0 2.164 2.164h21.638a2.162 2.162 0 0 0 2.164-2.164z"
								transform="translate(-.36 -.184)"
								style="fill: #fff"
							/>
						</g>
					</g>
					<g data-name="Group 1467">
						<g data-name="Group 1466" style="clip-path: url(#16bzuuhxda)">
							<path
								data-name="Path 2139"
								d="M433.7 163.545a1.823 1.823 0 0 0 1.828-1.827v-9.742a1.814 1.814 0 0 0-1.759-1.827 1.937 1.937 0 0 0-1.9 1.827v9.742a1.828 1.828 0 0 0 1.828 1.827m0 2.519a2.394 2.394 0 1 0 2.394 2.394 2.394 2.394 0 0 0-2.394-2.394"
								transform="translate(-.551 -.225)"
								style="fill: #c62772"
							/>
							<path
								data-name="Path 2140"
								d="m452.74 170.915-16.24-27.706a3.28 3.28 0 0 0-5.633 0l-16.232 27.706a3.2 3.2 0 0 0 2.811 4.8h32.478a3.2 3.2 0 0 0 2.814-4.8m-20.884-18.953a1.828 1.828 0 0 1 3.655 0v9.738a1.815 1.815 0 0 1-1.759 1.827 1.932 1.932 0 0 1-1.9-1.827zm1.828 18.877a2.393 2.393 0 1 1 2.394-2.393 2.394 2.394 0 0 1-2.394 2.393"
								transform="translate(-.529 -.212)"
								style="fill: #fff"
							/>
						</g>
					</g>
					<g data-name="Group 1474">
						<path
							data-name="Path 2141"
							d="M330.31 121.951a10.022 10.022 0 1 1-10.021-10.02 10.02 10.02 0 0 1 10.021 10.02"
							transform="translate(125.733 24.069)"
							style="fill: #ff5f42"
						/>
						<text
							data-name="85"
							transform="translate(440 149)"
							style="
								font-size: 10px;
								font-family: Quicksand-Bold, Quicksand;
								font-weight: 700;
								fill: #fff;
							"
						>
							<tspan x="0" y="0">85</tspan>
						</text>
					</g>
					<path
						data-name="Rectangle 1613"
						style="fill: none"
						d="M0 0h466v276H0z"
					/>
				</svg>

				<!-- <svg
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100; width: 100px"
					xml:space="preserve"
				>
					<g>
						<rect
							x="15.7"
							y="51.2"
							transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.6467 37.4733)"
							style="fill: #f29100"
							width="17.4"
							height="35.7"
						/>
						<polygon
							style="fill: #e83331"
							points="30.9,87.7 5.7,62.5 18,50.2 	"
						/>
						<path
							style="fill: #98245c"
							d="M30.9,49.6c-3.4,3.4-8.9,3.4-12.3,0L69,100l12.3-12.3L30.9,37.3C34.3,40.7,34.3,46.2,30.9,49.6z"
						/>
						<path
							style="fill: #e82275"
							d="M36.1,67l33,33l12.3-12.3C81.3,87.7,39.5,70.4,36.1,67z"
						/>
						<path
							style="fill: #adc51c"
							d="M82,74.7c3.4-3.4,8.9-3.4,12.3,0L43.9,24.3L31.6,36.5L82,87C78.7,83.6,78.7,78.1,82,74.7z"
						/>
						<path
							style="fill: #6ab42a"
							d="M43.9,24.3L31.6,36.5l32,32C60.2,65.2,43.9,24.3,43.9,24.3z"
						/>
						<path
							style="fill: #8bf0de"
							d="M67.4,46.1c0.5,0.3,0.9,0.6,1.3,1c0.7,0,1.5-0.1,2.1,0.3c2.7,0.7,5.4,1,8.1,0.7 c8.6-1,11.2-6.1,6.8-13.5c-0.6-1-1.1-2-1.8-2.9c-2-2.6-7.1-2-8.4,0.9c-0.4,1-1.1,1.4-2.1,1.2c-1.2-0.2-1.7-1.2-1.7-2.2 c0-2.7,1.7-4,4.1-4.7c3-0.8,5.9-0.4,8.8,0.3c2.1,0.6,2.7-0.6,2.9-2.2c0.2-1.4-0.5-2.6-1.4-3.7c-2.4-3.1-5.3-5.6-8.1-8.3 c-3.8-3.9-8-7.5-9.4-13c-8,7.8-15.9,15.6-23.8,23.4C52,31.3,59.6,38.8,67.4,46.1z"
						/>
						<path
							style="fill: #5ccbb5"
							d="M68.6,0c-8,7.8-15.9,15.6-23.8,23.4c5.1,5.6,10.5,10.9,15.9,16.2c-0.6-3.2-0.9-6.5-0.9-9.8 C59.8,18.8,63,8.6,68.6,0z"
						/>
					</g>
				</svg>

				<div>
					<span><h2 class="goreloheading font--text">Gorelo</h2></span>
				</div> -->

				<!-- signUpForm Starts -->
				<v-layout mt-4>
					<v-flex> </v-flex>
					<v-flex class="signUpForm1 pl-xs-3 pr-xs-3 pt-5">
						<v-layout row wrap>
							<v-flex xs12 text-xs-center class="feedbacktext font--text">
								<div class="xxxlg--text fw-600 pb-4">
									Thank you for signing up to Gorelo.
								</div>
								<span class="xlg--text"
									>We have sent you an email with login instructions.</span
								>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex> </v-flex>
				</v-layout>
				<!-- signUpForm Ends -->
				<v-layout mt-4>
					<v-flex> </v-flex>
					<v-flex class="signUpForm1 pl-xs-3 pr-xs-3 pt-5">
						<v-layout row wrap>
							<v-flex xs12 text-xs-center class="feedbacktext font--text">
								<v-btn :disabled="isSubmit" class="signInBtn" depressed>
									<a
										href="https://kb.gorelo.io/en/"
										class="text-decoration-none"
										>Knowledge Base</a
									>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex> </v-flex>
				</v-layout>
			</div>
			<!-- signUpLogo Ends -->
		</v-flex>
	</v-layout>
</template>

<script src="./signup-feedback.ts"></script>
<style scoped>
</style>
