import axios, { AxiosInstance } from 'axios';
import { Store } from 'vuex';
import { setupResponseInterceptor } from './setup-response-interceptor';
import { setupRequestInterceptor } from './setup-request-interceptor';

export interface IHttpService extends AxiosInstance {
    store?: Store<any>;
    router?: any;
}

export let HttpService: IHttpService = axios.create();

export const initHttpService = (store?: any, router?: any) => {

    HttpService = axios.create({
        //baseURL: store && store.state.app.config.api.baseUrl,
    });

    HttpService.store = store;
    HttpService.router = router;

    setupRequestInterceptor(HttpService);

    setupResponseInterceptor(HttpService);
};
