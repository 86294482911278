import { createI18n } from 'vue-i18n';
import en from '@/common/locales/i18n/en_US.json';
import ja from './i18n/ja-JP.json';

// Define number and date formats
const numberFormats:any = {
    'en': {
        currency: {
            style: 'currency', currency: 'USD', currencyDisplay: 'name'
        }
    },
    'ja': {
        currency: {
            style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
        }
    }
};

const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'ja': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
};

// Create I18n instance

export default createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            lang: en,
        },
        ja: {
            lang: ja,
        },
    },
    numberFormats: numberFormats,
    dateTimeFormats: dateTimeFormats,
});
