<template>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="form-group">
        <label for="name">Name:</label>
        <input 
          type="text" 
          id="name" 
          v-model="formData.name" 
          required
          class="form-control"
        >
      </div>
  
      <div class="form-group">
        <label for="email">Email:</label>
        <input 
          type="email" 
          id="email" 
          v-model="formData.email" 
          required
          class="form-control"
        >
      </div>
  
      <!-- Changed to use id instead of ref -->
      <div 
        id="cf-turnstile" 
        class="cf-turnstile"
      ></div>
  
      <button type="submit" :disabled="isSubmitting">
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
  
      <div v-if="message" :class="['message', messageType]">
        {{ message }}
      </div>
    </form>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'test',
    data() {
      return {
        formData: {
          name: '',
          email: '',
          turnstileToken: ''
        },
        isSubmitting: false,
        message: '',
        messageType: '',
        widgetId: null // Store the widget ID for reset
      };
    },
    mounted() {
      // Wait for next tick to ensure DOM is ready
      this.$nextTick(() => {
        if (window.turnstile) {
          this.widgetId = window.turnstile.render('#cf-turnstile', {
            sitekey: '0x4AAAAAAAzwEZR_EMlYIHh8',
            theme: 'light',
            callback: (token) => {
              this.formData.turnstileToken = token;
            }
          });
        }
      });
    },
    // Clean up when component is destroyed
    beforeUnmount() {
      if (window.turnstile && this.widgetId) {
        window.turnstile.remove(this.widgetId);
      }
    },
    methods: {
      async submitForm() {
        try {
          this.isSubmitting = true;
          this.message = '';
          
          const response = await axios.post(
            'http://localhost:7063/api/test',
            this.formData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
  
          this.message = 'Form submitted successfully!';
          this.messageType = 'success';
          
          // Reset form
          this.formData = {
            name: '',
            email: '',
            turnstileToken: ''
          };
          
          // Reset turnstile
          if (window.turnstile && this.widgetId) {
            window.turnstile.reset(this.widgetId);
          }
  
        } catch (error) {
          console.error('Submission error:', error);
          this.message = 'Error submitting form. Please try again.';
          this.messageType = 'error';
        } finally {
          this.isSubmitting = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
  }
  
  .error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
  }
  
  .cf-turnstile {
    margin: 15px 0;
  }
  </style>