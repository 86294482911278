import { DateTime } from "luxon";

export const webDomainValidator = (value: any) => {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    if (value.split('.').length == 2) {
        //without subdomain like abc.com
        return /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,62}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/.test(value);
    }
    else {
        //with subdomain like abc.co.uk
        let prefix = value.substring(0, value.indexOf("."));
        if (prefix.length > 64)
            return false;

        return /^[a-zA-Z0-9]+([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+){1,61}[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(value);

    }
}

//Time Spent
export const customTimeValidator = (value: any) => {
    if (value == '00:00') {
        return false;
    }
    return true;
}

export const validateTime = (value: any) => {
    if (value != null && value.length == 5) {
        var arr = value.split(':');
        var hours = parseInt(arr[0]);
        var mins = parseInt(arr[1]);
        if (hours == 99) {
            if (mins > 59) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return true;
        }
    }
    return true;
}

export const validateMinutes = (value: any) => {
    if (value != null && value.length == 5) {
        var arr = value.split(':');
        var mins = parseInt(arr[1]);
        if (mins > 59) {
            return false;
        }
        else {
            return true;
        }
    }
    return true;
}

export const validateHours = (value: any) => {
    if (value != null && value.length == 5) {
        var arr = value.split(':');
        var hours = parseInt(arr[0]);
        if (hours > 23) {
            return false;
        }
        else {
            return true;
        }
    }
    return true;
}

export const moreThanOneItems = (value: any) => {
    if (value != null && value.length > 0) {
        let number = parseFloat(value);
        if (number == 0 || number == 0.0 || value == ".") {
            return false;
        }
        return true;
    }
    return true;

}

export const valueNotZero = (value: any) => {
    if (value != null && value.length > 0) {
        let number = parseFloat(value);
        if (number == 0 || number == 0.0 || value == ".") {
            return false;
        }
        return true;
    }
    return true;

}

export const acceptValidQuantity = (value: any) => {
    if (value != null && value.length > 0) {
        if (typeof (value) === "string") {
            var temp = parseInt(value);
            if (temp <= 100) {
                return true
            }
            return false;
        }
        else {
            if (value <= 100) {
                return true
            }
            return false;
        }
    }
    return true;

}

export const emailStandard = (value: any) => {
    if (value != null && value.length > 0) {
        let prefix = value.substring(0, value.lastIndexOf("@"));
        if (prefix.length > 64) {
            return false;
        }
        else {
            return true;
        }
    }
    return true;
}


export const customDateValidator = (value: string) => {
    if (value != null && value.length > 0) {
        if (value.length != 10)
            return false;

        let date = DateTime.fromFormat(value, "MM-dd-yyyy");

        if (date < DateTime.fromFormat('01-01-1920', 'MM-DD-YYYY') || date > DateTime.fromFormat('12-31-2120', 'MM-DD-YYYY')) {
            return false;
        }

        return date.isValid;
    }
    return true;
}


