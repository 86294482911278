<template>
    <div>
        <router-view></router-view>
        <!-- <v-app id="inspire">
             <v-layout column fill-height>
                    <v-layout column>
                        <router-view></router-view>
                    </v-layout>
             </v-layout>
        </v-app> -->

    </div>
</template>
<style lang="scss">
    @import url("../common/design/site.scss");
</style>
<script src="./master.ts"></script>