import { AxiosRequestConfig } from 'axios';
import { HttpService } from '@/common/helper/http-service/http-service';


export module HttpHelper {

    //const headers: any = {
    //    "Access-Control-Allow-Origin": "*",
    //    "Access-Control-Allow-Headers": "X-Custom-Header",
    //    "Access-Control-Allow-Methods": "GET",
    //    "Access-Control-Allow-Credentials": "true"
    //};

    let isLocalhost: boolean = window.location.href.indexOf('localhost:') != -1;

    export function postData(url: any, params: any) {

        return new Promise<any>((resolve, reject) => {

            let config = <AxiosRequestConfig>{
                headers: { 'Content-Type': 'application/json' }
            };

            HttpService.post(url, JSON.stringify(params), config)
                .then(
                response => response.data
                )
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    export function patchData(url: any, params: any) {

        return new Promise<any>((resolve, reject) => {

            let config = <AxiosRequestConfig>{
                headers: { 'Content-Type': 'application/json' }
            };

            HttpService.patch(url, JSON.stringify(params), config)
                .then(
                    response => response.data
                )
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    export function postBlobData(url: any, params: any) {

        return new Promise<any>((resolve, reject) => {

            let config = <AxiosRequestConfig>{
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    //Error 
                });
        });

    }

    export function postDataWithHeader(url: any, params: any, config:any) {

        return new Promise<any>((resolve,reject) => {

            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    //Error 
                    reject();
                });
        });

    }
    
    export function deleteData(url: any, params: any) {

        return new Promise<any>((resolve, reject) => {

            let config = <AxiosRequestConfig>{
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(params),
            }

            HttpService.delete(url, config)
                .then(response => response.data)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    //Error 
                    reject();
                });
        });

    }

    export function getData<T=any>(url: any, params: any = null) {

        return new Promise<any>((resolve, reject) => {

            HttpService.get<T>(url, <AxiosRequestConfig>{ data: params })
                .then(response => response.data)
                .then(data => {                  
                    resolve(data);

                })
                .catch(error => {
                    reject();
                });
        });

    }

    export function getDataWithHeader<T=any>(url: any,config:any) {

        return new Promise<any>((resolve, reject) => {

            HttpService.get<T>(url, config)
                .then(response => response.data)
                .then(data => {
                    resolve(data);

                })
                .catch(error => {
                    reject();
                });
        });

    }

    export function postFormData(url: any, formData: any) {

        return new Promise<any>((resolve, reject) => {

            let config = <AxiosRequestConfig>{
                headers: { 'Content-Type': 'multipart/form-data' },
            }

            HttpService.post(url, formData, config)
                .then(response => response.data)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                });
        });

    }

    function IsTokenExpired(response: any): boolean {
        if (response.isTokenExpired) {
            var url = window.location.href
            var arr = url.split("/");
            var Domain = arr[0] + "//" + arr[2];
            window.location.href = Domain + "/" + "Session/SigIn?CurrentController=" + arr[3];
            return true;
        }
        return false

    }

}
