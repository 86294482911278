import { createApp, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Vuelidate from '@vuelidate/core';
import { Quasar, Notify } from 'quasar';
import 'quasar/src/css/index.sass'
import iconSet from 'quasar/icon-set/fontawesome-v6'
import i18n from '@/common/locales';
import signup from '@/account/views/signup.vue';
import SignupFeedback from '@/account/views/signup-feedback.vue';
import PrivacyPolicy from '@/account/views/privacy-policy.vue';
import invitelink from '@/account/views/invitelink.vue';
import Master from './master.vue';
import test from './views/test.vue';

i18n.global.locale = 'en';

const routes = [
    { path: '/', name: 'signup', component: signup },
    { path: '/signup-feedback', name: 'signup-feedback', component: SignupFeedback },
    { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy },
    { path: '/test', name: 'test', component: test },
];

const myIcons = {
    //for Localhost
    //'app:buildingduotonesolid': 'img:../src/common/images/building-duotone-solid.svg',
    //'app:buildingduotone': 'img:../src/common/images/building-duotone.svg',
    //'app:housebuildingduotonesolid': 'img:../src/common/images/house-building-duotone-solid.svg',
    //'app:housebuildingduotone': 'img:../src/common/images/house-building-duotone.svg',

    //For Dev and Prod
    'app:multibuidlingiconduotone': 'img:/multiBuildingIcon-Duotone.svg',
    'app:multibuidlingicon': 'img:/multiBuildingIcon.svg',
    'app:buildingduotonesolid': 'img:/building-duotone-solid.svg',
    'app:buildingduotone': 'img:/building-duotone.svg',
    'app:housebuildingduotonesolid': 'img:/house-building-duotone-solid.svg',
    'app:housebuildingduotone': 'img:/house-building-duotone.svg',
}

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp({
    render: () => h(Master),
});

app.use(router);
app.use(Quasar, {
    config: {
        dark: true,
        iconMapFn: (iconName) => {
            const icon = myIcons[iconName];
            if (icon !== undefined) {
                return { icon };
            }
        },
    },  
    plugins: {
        Notify
    },
    iconSet,
} as any);
app.use(i18n);
app.use(Vuelidate);

app.component('CustomDropdownComponent', () => import('@/common/components/dropdown/dropdown.vue'));

// Mount app to the DOM
console.log("mounting app")
app.mount('#app');

// Azure AD B2C configuration
var UseAzureAdB2C = ((window as any).UseAzureAdB2C) as string
if (UseAzureAdB2C == "False") {
    app.config.performance = true;
    if ((module as any).hot) {
        console.log("test");
        (module as any).hot.accept();

    }
}