
declare global {
    interface Window {
        getTimeZoneAzureFunction: string;
        createServiceProviderFunction: string;
    }
}

namespace gorelo.web {
    export interface IApiConstants {
        actions: {
            gettimezoneazurefunction: string;
            createserviceproviderazurefunction: string;
        }

        code: {
            gettimezoneazurefunctioncode: string;
            createserviceproviderazurefunctioncode: string;
        }
    }
}

const signupFunctionBaseUrl = import.meta.env.VITE_signupAPIUrl;

// timeout constant
export const dropdownSearchTimeout = 300;



export const apiConstants: gorelo.web.IApiConstants = {

    actions: {
        gettimezoneazurefunction: "GetTimezones",
        createserviceproviderazurefunction: "CreateServiceProviderFunction"
    },
    code: {
        gettimezoneazurefunctioncode: import.meta.env.VITE_getTimeZoneAzureFunctionCode,
        createserviceproviderazurefunctioncode: import.meta.env.VITE_CreateServiceProviderFunctionCode
    }
};

export const constants = {

    apiUrl: {

        serviceProviderUrls: {
            createServiceProvider: signupFunctionBaseUrl + "/api/" + apiConstants.actions.createserviceproviderazurefunction + "?code=" + apiConstants.code.createserviceproviderazurefunctioncode,
            getTimeZoneAzureFunction: signupFunctionBaseUrl + "/api/" + apiConstants.actions.gettimezoneazurefunction + "?code=" + apiConstants.code.gettimezoneazurefunctioncode
        },

        
    },
    localStorage: {
        keys: {
            rules: "rules",
            routes: "routes",
        }
    },
    validations: {
        phoneNumberMinLength: 6,
        phoneNumberMaxLength: 15,
        emailMaxLength: 200
    },
    AppVersion: "api-version=1.0",
};

