import { defineComponent } from 'vue';
export default defineComponent({
    name: "SignupFeedback",

    /************************************/
    /* Component Section *
    /************************************/


    /************************************/
    /* Computed Section *
    /************************************/

    computed: {

    },

    /************************************/
    /* Watch Section *
    /************************************/



    /************************************/
    /* Data Object Section *
    /************************************/

    data() {
        return {


        }
    },

    /************************************/
    /* Validation Section *
    /************************************/

    validations: {

    },

    /************************************/
    /* Method Section *
    /************************************/

    methods: {

    },

    /************************************/
    /* Created Section *
    /************************************/

    created() {


    },

    /************************************/
    /* Mounted Section *
    /************************************/

    mounted() {
    },

    /************************************/
    /* Destroyed Section *
    /************************************/

    destroyed() {
    }

})
