import { constants } from '@/common/constant/constants';

export module UrlHelper {

    export function buildUrl(url: any, parameters: any) {
        let qs = "";
        for (const key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                const value = parameters[key];
                qs +=
                    encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
            }
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1); //chop off last "&"
            url = url + "?" + qs;
        }

        return url;
    }

    export function getUrl(url: string) {
        //let isLocalhost = window.location.href.indexOf('localhost:') != -1;

        //if (url.indexOf("?") != -1) {
        //    url += "&" + constants.AppVersion;
        //}
        //else {
        //    url += "?" + constants.AppVersion;
        //}
        return url;
    }

    export function redirectToSignIn() {
        localStorage.setItem('IdToken', "");
        var url = window.location.href
        var arr = url.split("/");
        var Domain = arr[0] + "//" + arr[2];
        window.location.href = Domain + "/" + "Session/SigIn?CurrentController=" + arr[3];
    }
}
