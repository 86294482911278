import { UrlHelper } from '@/common/helper/url-helper-service'
import { HttpHelper } from '@/common/helper/http-helper-service'
import { constants } from '@/common/constant/constants';

export default class ServiceProviderServices {
    constructor() {
    }

    static saveServiceProvider = (params: object) => {
        let url = constants.apiUrl.serviceProviderUrls.createServiceProvider;
        url = UrlHelper.getUrl(url);
        return HttpHelper.postData(url, params);
    }

    static getServieproviderTimeZoneList = () => {
        return HttpHelper.getData(constants.apiUrl.serviceProviderUrls.getTimeZoneAzureFunction);
    }
}
